import React from "react"
import { graphql, Link } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import _ from "lodash"
import moment from "moment"
import Layout from "../../components/layout/layout"
import LocationTile from "../../components/festival/location-tile"
import PageTransition from "../../components/transition/page"
import PageHeader from "../../components/layout/page-header"
import SEO from "../../components/seo/seo"

class FestivalsPage extends React.Component {
  constructor(props) {
    super(props)
    this.pageTransition = React.createRef()
  }
  animate() {
    this.pageTransition.current.play()
  }
  render() {

    const { path, data } = this.props
    const locale = "en-AU"
    const events = []

    data.allFestivals.festivals.forEach((locations, index) => locations.locations.items.forEach((item, i) => events.push(item)))
    events.sort((a, b) => moment(b.date).isBefore(a.date) ? 1 : -1)

    let locationGroups = _.chunk(events, 3)
    let locationGroupsMobile = _.chunk(events, 2)

    return (
      <Layout locale={locale} theme="light" path={path} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
        <SEO
          title={data.seo.title}
          description={data.seo.description}
          keywords={data.seo.keywords && data.seo.keywords.keywords}
          image={data.seo.image.file.url}
        />
        <PageHeader bottom={false}>
          <video muted playsInline autoPlay loop className={"w-full px-12 relative"} style={{ zIndex: 100 }}>
            <source src={data.video.file.url} type="video/mp4" />
          </video>
        </PageHeader>
        <section className={"container mx-auto px-12 relative"} style={{ zIndex: 1000 }}>
          <div className={"flex flex-wrap hidden sm:hidden md:flex md:-mt-4 lg:-mt-8 px-16"}>
            {locationGroups.map((group, index) => {
              return group.map((item, gindex) => {
                return (
                  <div className={"w-1/2 md:w-1/3"} key={gindex}>
                    <Link to={`/${locale}/locations/${item.slug}`}>
                      <LocationTile
                        title={item.title}
                        day={moment.parseZone(item.date).format("ddd")}
                        month={moment.parseZone(item.date).format("D MMMM")}
                        file={item.tile.file.url}
                        align={index & 1 ? "right" : ""}
                      />
                    </Link>
                  </div>
                )
              })
            })}
            <div className={"w-1/2 md:w-1/3"}>
              <LocationTile
                title={"BRAZIL"}
                text={"Coming Soon"}
                file={"https://images.ctfassets.net/8ipgrsmep6cq/2ifZJHYaCOz1rn8xEtYfjy/30a24a96904cf7a309cce8f0e2b4191c/Tiles_Event_Waves.svg"}
                align={"left"}
              />
            </div>
            <div className={"w-1/2 md:w-1/3"}>
              <LocationTile
                title={"JAPAN"}
                text={"Coming Soon"}
                file={"https://images.ctfassets.net/8ipgrsmep6cq/Ix1qg1ShkqnNiTo60yRA0/5aa3c701d42fa8a07ec84003eab5bec7/Tiles_Event_Palm.svg"}
                align={"left"}
              />
            </div>
            <div className={"w-1/2 md:w-1/3"}>
              <LocationTile
                title={"HAWAII"}
                text={"Coming Soon"}
                file={"https://images.ctfassets.net/8ipgrsmep6cq/0R9SxRKBZmBwAW70fvE7V/19d0ac14220ea688c25d4be51d709554/Tiles_Event_Leaf.svg"}
                align={"right"}
              />
            </div>
            <div className={"w-1/2 md:w-1/3"}>
              <LocationTile
               title={"PORTUGAL"}
               text={"Coming Soon"}
               file={"https://images.ctfassets.net/8ipgrsmep6cq/5gvm7J0ZO8bJaAj1Tng3qs/ba28e62cc4b912d15c10a98db6c0bcdc/Tiles_Event_Flower.svg"}
                align={"right"}
              />
            </div>
            <div className={"w-1/2 md:w-1/3"}>
              <LocationTile
                title={"FRANCE"}
                text={"Coming Soon"}
                file={"https://images.ctfassets.net/8ipgrsmep6cq/4jvoIJly5ZOGrOarqt2cQF/48e4a1842426a61cc4f4e08d4ed216fe/Tiles_Event_Fern.svg"}
                align={"right"}
              />
            </div>
          </div>
          <div className={"flex flex-wrap md:hidden -mt-2"}>
            {locationGroupsMobile.map((group, index) => {
              return group.map((item, gindex) => {
                return (
                  <div className={`w-1/2 mx-auto`} key={gindex}>
                    <Link to={`/${locale}/locations/${item.slug}`}>
                      <LocationTile
                        title={item.title}
                        day={moment.parseZone(item.date).format("ddd")}
                        month={moment.parseZone(item.date).format("MMM Do")}
                        file={item.tile.file.url}
                        align={"center"}
                      />
                    </Link>
                  </div>
                )
              })
            })}
            <div className={"w-1/2 md:w-1/3"}>
              <LocationTile
                title={"BRAZIL"}
                text={"Coming Soon"}
                file={"https://images.ctfassets.net/8ipgrsmep6cq/2ifZJHYaCOz1rn8xEtYfjy/30a24a96904cf7a309cce8f0e2b4191c/Tiles_Event_Waves.svg"}
                align={"center"}
              />
            </div>
            <div className={"w-1/2 md:w-1/3"}>
              <LocationTile
                title={"JAPAN"}
                text={"Coming Soon"}
                file={"https://images.ctfassets.net/8ipgrsmep6cq/Ix1qg1ShkqnNiTo60yRA0/5aa3c701d42fa8a07ec84003eab5bec7/Tiles_Event_Palm.svg"}
                align={"center"}
              />
            </div>
            <div className={"w-1/2 md:w-1/3"}>
              <LocationTile
                title={"HAWAII"}
                text={"Coming Soon"}
                file={"https://images.ctfassets.net/8ipgrsmep6cq/0R9SxRKBZmBwAW70fvE7V/19d0ac14220ea688c25d4be51d709554/Tiles_Event_Leaf.svg"}
                align={"center"}
              />
            </div>
            <div className={"w-1/2 md:w-1/3"}>
              <LocationTile
               title={"PORTUGAL"}
               text={"Coming Soon"}
               file={"https://images.ctfassets.net/8ipgrsmep6cq/5gvm7J0ZO8bJaAj1Tng3qs/ba28e62cc4b912d15c10a98db6c0bcdc/Tiles_Event_Flower.svg"}
                align={"center"}
              />
            </div>
            <div className={"w-1/2 md:w-1/3"}>
              <LocationTile
                title={"FRANCE"}
                text={"Coming Soon"}
                file={"https://images.ctfassets.net/8ipgrsmep6cq/4jvoIJly5ZOGrOarqt2cQF/48e4a1842426a61cc4f4e08d4ed216fe/Tiles_Event_Fern.svg"}
                align={"center"}
              />
            </div>
          </div>
        </section>
        <TransitionPortal>
          <PageTransition ref={this.pageTransition} />
        </TransitionPortal>
      </Layout>
    )
  }
}

export const query = graphql`
  query  {
    seo: contentfulSeo(slug: {eq: "festival"}) {
      title
      description
      keywords {
        keywords
      }
      image {
        file {
          url
        }
      }
    }
    video: contentfulAsset(contentful_id: { eq: "w39bNX4xfnoTLqYC9wnJk" }, node_locale: { eq: "en-AU" }) {
      file {
        url
      }
    }
    allFestivals: allContentfulFestival(limit: 50, filter: { node_locale: { eq: "en-AU" } }) {
      festivals: edges {
        locations: node {
          items: locations {
            ... on ContentfulLocation {
              slug
              title
              venue
              date
              festival {
                title
              }
              tile {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

export default FestivalsPage
